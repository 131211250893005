@font-face {
  font-family: 'algoed-icons';
  src:  url('fonts/algoed-icons.eot?b9mied');
  src:  url('fonts/algoed-icons.eot?b9mied#iefix') format('embedded-opentype'),
    url('fonts/algoed-icons.ttf?b9mied') format('truetype'),
    url('fonts/algoed-icons.woff?b9mied') format('woff'),
    url('fonts/algoed-icons.svg?b9mied#algoed-icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'algoed-icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-copy-link:before {
  content: "\e931";
}
.icon-files:before {
  content: "\e930";
}
.icon-timer:before {
  content: "\e92f";
}
.icon-schedule:before {
  content: "\e92e";
}
.icon-clock:before {
  content: "\e92d";
}
.icon-box-grid:before {
  content: "\e92b";
}
.icon-attachment:before {
  content: "\e92c";
}
.icon-send:before {
  content: "\e92a";
}
.icon-heart-fill:before {
  content: "\e929";
}
.icon-heart:before {
  content: "\e928";
}
.icon-share-icon:before {
  content: "\e927";
}
.icon-result:before {
  content: "\e926";
}
.icon-info:before {
  content: "\e924";
}
.icon-download:before {
  content: "\e923";
}
.icon-filter:before {
  content: "\e922";
}
.icon-checkbox:before {
  content: "\e925";
}
.icon-submission:before {
  content: "\e921";
}
.icon-lock-default:before {
  content: "\e920";
}
.icon-arrow-toggle:before {
  content: "\e91f";
}
.icon-arrow-left:before {
  content: "\e91e";
}
.icon-trophy:before {
  content: "\e91d";
}
.icon-org-settings:before {
  content: "\e919";
}
.icon-assign-task:before {
  content: "\e91a";
}
.icon-group-students:before {
  content: "\e91c";
}
.icon-manage-students:before {
  content: "\e918";
}
.icon-dashboard-new:before {
  content: "\e917";
}
.icon-check:before {
  content: "\e915";
}
.icon-upload-arrow:before {
  content: "\e916";
}
.icon-angle-right:before {
  content: "\e914";
  color: #595959;
}
.icon-add:before {
  content: "\e912";
}
.icon-lightning:before {
  content: "\e913";
}
.icon-right-traingle:before {
  content: "\e91b";
}
.icon-profile:before {
  content: "\e910";
}
.icon-university:before {
  content: "\e911";
}
.icon-academic-cap:before {
  content: "\e90f";
}
.icon-activities:before {
  content: "\e90e";
}
.icon-graph:before {
  content: "\e90d";
}
.icon-rounded-plus:before {
  content: "\e90c";
}
.icon-file:before {
  content: "\e90b";
}
.icon-sms:before {
  content: "\e90a";
}
.icon-close-circle:before {
  content: "\e902";
}
.icon-insider:before {
  content: "\e903";
}
.icon-dashboard:before {
  content: "\e904";
}
.icon-message-question:before {
  content: "\e905";
}
.icon-essay:before {
  content: "\e907";
}
.icon-user-edit:before {
  content: "\e908";
}
.icon-user-square:before {
  content: "\e909";
}
.icon-dollar-square:before {
  content: "\e901";
}
.icon-key:before {
  content: "\e900";
}
.icon-question:before {
  content: "\e906";
}
