@import "./colors-palette.scss";

.p-checkbox {
  .p-checkbox-box {
    &.p-highlight {
      border-color: $ternary;
      background: $ternary;
      &:hover {
        border-color: $ternary !important;
        background: $ternary !important;
      }
    }
  }
  &:not(.p-checkbox-disabled) {
    .p-checkbox-box {
      &:hover {
        border-color: $ternary !important;
      }

      &:focus {
        box-shadow: 0 0 0 0.2rem rgba($color: $ternary, $alpha: 0.25);
      }
    }
  }
}

@mixin custom-checkbox-theme($color) {
  .p-checkbox {
    .p-checkbox-box {
      &.p-highlight {
        border-color: $color;
        background: $color;
        &:hover {
          border-color: $color !important;
          background: $color !important;
        }
      }
    }

    &:not(.p-checkbox-disabled) {
      .p-checkbox-box {
        &:hover {
          border-color: $color !important;
        }

        &:focus {
          box-shadow: 0 0 0 0.2rem rgba($color, 0.25);
        }
      }
    }
  }
}

p-checkbox {
  &.primary-blue {
    @include custom-checkbox-theme(#065ad8);
  }

  &.judge-primary-checkbox {
    @include custom-checkbox-theme($judge-primary);
    .p-checkbox-box {
      border-radius: 5px;
    }
  }

  &.admin-primary {
    @include custom-checkbox-theme(#3247c6);
  }
}
