$primary: #4154ca;
$darkblue1: #4154ca;
$darkgrey: #4b4b4b;
$darkgrey2: #292d32;
$darkgrey3: #404040;
$darkgrey4: #575757;
$darkgrey5: #313131;
$darkgrey6: #dee2e6;
$white: #ffffff;
$lightgreen: #7bc93d;
$red: #e30000;
$lightred1: rgba(227, 0, 0, 0.08);
$lightred3: rgba(227, 0, 0, 0.1);
$darkred1: rgba(227, 0, 0, 0.5);
$darkred2: rgba(237, 0, 0, 0.12);
$darkblue: #555bb34d;
$darkred: #ff0000;
$blue: #3d50c9;
$lightblue: rgba(65, 83, 202, 0.2);
$green: #5bbc0d;
$green1: #6bca20;
$green3: #199c7d;
$lightgreen1: rgba(123, 201, 61, 0.14);
$darkgreen: rgba(123, 201, 61, 0.5);
$darkgreen2: rgba(123, 201, 61, 0.12);
$purple: #7e1acc;
$lightpurple: rgba(126, 26, 204, 0.08);
$lightgrey: #a9abad;
$lightgrey1: #ededed;
$lightgrey2: #a9a9a9;
$lightgrey3: #a0a0a0;
$lightgrey4: #494949;
$lightgrey5: #c9c9c9;
$black: #000000;
$red-button: #ed0000;
$yellow: #f19100;
$yellow2: #f9cc45;
$lightyellow: rgba(241, 145, 0, 0.1);
$darkyellow: rgba(241, 145, 0, 0.5);
$opacityoneblack: rgba(0, 0, 0, 0.5);
$lightgreen2: rgba(123, 201, 61, 0.1);
$lightred2: rgb(227, 0, 0, 0.1);
$darkgrey7: #f5f8ff;
$darkgrey8: #dcdcdc;
$darkgrey9: #c5c5c5;
$lightpurple2: rgba(85, 91, 179, 0.3);
$pink: #e8617c;
$lightgrey6: #b4bdc6;
$lightgrey7: #888c97;
$lightgrey8: #dfdfeb;
$lightgrey9: #eaedf9;
$lightgrey10: #f1f1f8;
$green4: #00916e;
$lightgrey11: #f6f8fa;
