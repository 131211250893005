@import "./colors-palette.scss";

.p-menubar
  .p-menubar-root-list
  > .p-menuitem
  > .p-menuitem-link:not(.p-disabled):hover {
  background: none !important;
}

.p-button {
  font-weight: 600;
  font-size: 13px;
  padding: 9px 20px;
  box-shadow: none;
  font-style: normal;
  letter-spacing: 1px;
  border-radius: 5px;
  &.p-button-lg {
    padding: 18px 0.625rem;
    border-radius: 4px;
  }
  &.p-button-sm {
    padding: 0.25rem 0.75rem;
  }

  &.p-button-link {
    color: #3247c6;
    padding: 0;
  }

  &.p-cancel-btn {
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    color: #797979;
  }
}

.p-primary {
  background: $primary;
  color: $white;
  border: 1px solid $primary;

  &.p-outline-primary {
    background: $white;
    color: $primary;
    border: 1px solid rgba($color: $primary, $alpha: 0.6);
  }
}
.p-danger {
  background: $red-button;
  color: $white;
  border: 1px solid $red-button;
}
.p-width-auto {
  display: block;
  height: max-content;
  width: 120px;
}

.p-white {
  background: $white;
  border: 1px solid $white;
  color: $primary;
}

.p-grey {
  background: $lightgrey6;
  color: $black;
  border: 1px solid $lightgrey6;
}

.full-width-button {
  width: 100%;
  margin-bottom: 20px !important;
}

.p-button-secondary {
  // background: none !important;
  border: 1px solid rgba(41, 46, 77, 0.2);
  color: #292e4d !important;
}

.custom-ternary {
  .p-button {
    background-color: $ternary;
    color: #ffffff;
    border-color: $ternary;
    border-radius: 5px;

    .icon {
      font-size: 18px;
    }

    &.p-button-outlined {
      border-color: $ternary;
      color: $ternary;
      background-color: $white;

      &:hover {
        background: $ternary;
        color: $white;
      }
    }

    &.p-button-rounded {
      border-radius: 50rem;
    }
  }
  &.p-button-remove {
    .p-button {
      border-color: transparent;
      color: #e54f6d;
      background-color: #fbeff2;
      font-weight: 500;
    }
  }
  &.p-light {
    .p-button {
      border-color: #eeeeee;
      color: #222222;
      background-color: #ffffff;
      font-weight: 500;
    }
  }

  &.p-info {
    .p-button {
      border-color: rgba($color: $primary, $alpha: 0.1);
      color: $primary;
      background-color: #ffffff;
      font-weight: 500;
    }
  }
}

.primary-light {
  .p-button {
    background-color: rgba($color: $primary, $alpha: 0.1);
    color: $primary;
    border-color: rgba($color: $primary, $alpha: 0.1);
    border-radius: 5px;
  }
}

.floating-btn {
  position: absolute;
  right: 16px;
  bottom: 16px;
}

p-button {
  &.edit-rounded-btn {
    .p-button {
      border: 1px solid #009ae0;
      border-radius: 50%;
      color: #009ae0;
      height: 34px;
      width: 34px;
    }
  }
}
