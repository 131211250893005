@import "./colors-palette.scss";

.p-calendar {
  width: 100%;
  .p-datepicker {
    border: 0;
    border-radius: 10px;
    // box-shadow: 0 0 0.25rem 2px rgba(226, 236, 249, 0.45);
    box-shadow: $box-shadow;
    // background: rgba($color: #e54f6d, $alpha: 0.05);
    .p-datepicker-header {
      background: transparent;
      border: none;
    }
    .p-link,
    .p-datepicker-next,
    .p-datepicker-prev,
    .p-datepicker-header {
      color: #282828;
    }
    .p-monthpicker {
      .p-monthpicker-month {
        height: 80px;
      }
    }
    .p-yearpicker-year {
      height: 80px;
    }
    &:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):hover {
      background: rgba($color: #e54f6d, $alpha: 0.05);
    }

    table td.p-datepicker-today > span {
      background-color: white;
      color: #e54f6d;
      border: 1px solid #e54f6d;
    }
    
    table td > span.p-highlight,
    &:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):hover {
      background: #e54f6d;
      color: $white;
    }


    table td > span:focus {
      box-shadow: 0 0 0 0.2rem rgba($color: $ternary, $alpha: 0.2);
    }
  }
}

.p-datepicker .p-timepicker span {
  font-size: 1rem;
}
