@import "./colors-palette.scss";

p-selectbutton {
  &.border-light {
    .p-selectbutton {
      border-color: #dcdcdc;
    }
  }

  &.sm {
    .p-selectbutton {
      border-radius: 6px !important;
    }
    .p-button {
      padding: 0.25rem 0.75rem;

      &.p-highlight {
        border-radius: 6px !important;
      }
    }
  }

  &.custom-ternary {
    .p-selectbutton {
      border: 1px solid $ternary;
      .p-button {
        color: #282828;
        &.p-highlight {
          color: $white;
          background-color: $ternary;
        }
      }
    }
  }
}

.p-selectbutton {
  padding: 5px;
  border: 1px solid $primary;
  border-radius: 3px;
  // margin-top: -10px;
  .p-button {
    background: transparent;
    border: none;
    &.p-highlight {
      &:hover {
        background-color: $primary;
      }
      background: $primary;
      border-radius: 3px !important;
    }
  }
}

.full-width {
  .p-selectbutton {
    display: flex;
    .p-button {
      width: 100%;
      justify-content: center;
    }
  }
}

.half-grid {
  .p-selectbutton {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

.black-color {
  .p-selectbutton {
    border: 1px solid $lightgrey3;
    .p-button {
      &.p-highlight {
        &:hover {
          background-color: $lightgrey4;
        }
        background: $lightgrey4;
      }
    }
  }
}
