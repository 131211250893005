.p-paginator {
  .p-paginator-current {
    align-self: flex-start;
    flex: 1;
    justify-content: left;
    color: #54577a;
    font-size: 14px;
  }
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: #3247c6;
  border-color: #3247c6;
  color: #ffffff;
}

.custom-pagination {
  .p-paginator {
    .p-paginator-next,
    .p-paginator-prev {
      border: 1px solid #cecece;
      margin: 0;
      border-radius: 50rem;
      color: #000000;
    }
    .p-paginator-prev {
      border-right: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    .p-paginator-next {
      border-left: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  &.judges-pagination {
    .p-paginator {
      background-color: transparent;
      border: 0;
      padding: 0;

      .p-paginator-current {
        color: #46495b;
        font-size: 14px;
        height: auto;
        align-self: center;
      }
    }

    @media screen and (max-width: 768px) {
      .p-paginator {
        margin-top: 1rem;
        background: #fff;
        padding: 0.5rem;
        border-radius: 10px;
        box-shadow: $box-shadow;
        .p-paginator-current {
          display: inline-block;
          min-width: initial !important;
          width: auto;
          padding: 0;
        }
      }
    }
  }
}
