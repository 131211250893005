.p-tabview {
  .p-tabview-nav {
    border-bottom: 1px solid #e8e8e8;
    .p-highlight {
      .p-tabview-nav-link {
        border: none;
        color: #535353 !important;
        font-weight: 600 !important;
        opacity: 1;

        &:before {
          background-color: #e54f6d;
        }
      }
    }

    .p-tabview-nav-link {
      color: #1d1d1d !important;
      opacity: 0.5;
      border-color: #e8e8e8 !important;
      font-size: 14px;
      font-weight: 400;
      &:before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: -2px;
        height: 5px;
        width: 100%;
        background-color: transparent;
        border-top-left-radius: 50rem;
        border-top-right-radius: 50rem;
      }
    }
  }
}

.student-primary-tab {
  .p-tabview {
    .p-tabview-nav {
      .p-highlight {
        .p-tabview-nav-link {
          &:before {
            background-color: #3247C6;
          }
        }
      }
    }

    .p-tabview-panels {
      display: none !important;
    }
  }
}
