@import "./colors-palette.scss";

.p-tree {
  border: none;
  padding: 20px 0;
  border-radius: 8px;
  .p-treenode-children {
    padding: 0;
    li {
      a {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
      }
    }
  }
  .p-treenode {
    padding: 10px 20px !important;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: $darkgrey2 !important;
    .p-treenode-content {
      flex-flow: row-reverse;
      justify-content: space-between;
    }
    a {
      text-decoration: none;
      color: $darkgrey2 !important;
      opacity: 0.6;
      position: relative;
      &.active {
        + b {
          color: $primary !important;
          opacity: 1;
        }

        opacity: 1;
        color: $primary !important;
        &::before {
          content: "";
          border-left: 5px solid $primary;
          border-radius: 0 4px 4px 0;
          position: absolute;
          left: -20px;
          top: 0;
          bottom: 0;
        }
      }
    }
  }
  .p-tree-toggler {
    color: $darkgrey2;
    opacity: 0.6;
  }
  .p-treenode-content {
    .p-treenode-label {
      b {
        color: $darkgrey2;
        opacity: 0.6;
        font-style: normal;
        font-weight: 500;
      }
    }
  }
}
