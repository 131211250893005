@import "./colors-palette.scss";

.p-tabmenu-nav-content {
  background: $primary;
  border-radius: 8px;
}

.p-tabmenu {
  .p-tabmenu-nav {
    background-color: $primary;
    padding: 10px;
    border-radius: 8px;
    border: 0;

    .p-tabmenuitem {
      .p-menuitem-link {
        background: none;
        color: $white;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        border: none;
        margin: 0 10px;
        &:hover {
          background: $white;
          color: $primary;
          border-radius: 4px;
          opacity: 1;
        }
      }
      &.p-highlight {
        .p-menuitem-link {
          background: $white;
          border-radius: 4px;
          color: $primary;
          border: none;
          opacity: 1;
        }
      }
    }
  }
}

.sub-menus {
  .p-tabmenuitem {
    .p-menuitem-link {
      opacity: 0.5;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      padding: 4px 20px;

      .p-menuitem-text {
        font-size: 14px;
      }

      &:hover {
        background: none !important;
        color: $white !important;
        opacity: 1;
      }
    }
    &.p-highlight {
      .p-menuitem-link {
        background: none !important;
        border-radius: 4px;
        color: $white !important;
        opacity: 1;
        span {
          padding-bottom: 4px;
          border-bottom: 1px solid $white !important;
        }
      }
    }
  }
}

.default-tabmenu {
  .p-tabmenu .p-tabmenu-nav {
    background: #ffffff;
    border: 1px solid #dee2e6;
    border-width: 1px;
    padding: 0;
  }
  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
    // color: #6c757d !important;
    color: black !important;
    opacity: 1 !important;
  }
  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
    border-bottom: 3px solid $primary;
    border-radius: 0;
    color: #4154ca !important;
  }
}
