@import "./colors-palette.scss";

.p-inputgroup {
  position: relative;
}

.p-inputtext {
  background: #f8fbff;
  border: 0;
  box-sizing: border-box;
  border-radius: 10px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.3px;
  color: $black;
  width: 100%;
  padding: 0.72rem 0.725rem;
  font-size: 14px;
}

.p-multiselect {
  width: 100%;
  padding: 5px;
  background: #f8fbff;
  border: 0;
  border-radius: 10px;
  .p-multiselect-label.p-placeholder {
    color: rgba($color: #1b1b1b, $alpha: 0.5);
    font-size: 14px;
  }
}

// Search Input
.pi-search {
  & + .p-inputtext {
    padding: 0.625rem 2rem;
    font-size: 14px;
    color: rgba($color: #1b1b1b, $alpha: 0.5);
    border: 0;
    background-color: #f8fbff;
  }
}

.p-editor-container .p-editor-content,
.p-editor-container .p-editor-content .ql-editor {
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.p-editor-container .p-editor-toolbar {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
