@import "./colors-palette.scss";

.p-dialog {
  border-radius: 8px;
  overflow: auto;
  .p-dialog-content {
    background: $white;
  }
  .p-dialog-header {
    font-weight: 600;
    font-size: 24px;
    text-transform: capitalize;
    color: $darkgrey5;
    background: $white;
  }
  .p-dialog-header-icons {
    background: $lightred3;
    border-radius: 4px;
    span {
      color: $red;
    }
  }
}

.p-dialog-mask{
    backdrop-filter: blur(5px);
}