.full-width {
  .p-dropdown {
    width: 100%;
    // padding: 7px;
  }
}

.p-dropdown-label {
  line-height: initial !important;
  border: none;
  display: flex;
  align-items: center;
  color: #6c757d;
}

.p-dropdown {
  max-height: 41px;
  background-color: #f9fbff;
  border-radius: 10px;
  border: 0;
  width: 100%;
  // min-width: 150px;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  font-size: 14px;
}
