@import "./colors-palette.scss";

.p-progressbar-label {
  visibility: hidden;
}

.p-progressbar {
  border-radius: 10px;
  .p-progressbar-value {
    background: $pink;
  }
}
