@import "./colors-palette.scss";

.p-menu {
  width: auto !important;
  border: none;
  ul {
    margin-left: -10px;
  }
  .p-menuitem-link {
    padding-top: 15px;
    padding-bottom: 15px;
    margin-bottom: 15px;
    .p-menuitem-text {
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: $darkgrey2;
    }
    .p-menuitem-icon {
      color: $darkgrey2;
    }
    &:not(.p-disabled):hover {
      background: $primary;
      color: $white;
      border-radius: 4px;
      .p-menuitem-icon,
      .p-menuitem-text {
        filter: brightness(100);
        color: $white;
      }
    }
  }
  .p-menuitem-link-active {
    background: $primary;
    color: $white;
    border-radius: 4px;
    .p-menuitem-icon,
    .p-menuitem-text {
      color: $white;
      filter: brightness(100);
    }
  }
}
