@import "./colors-palette.scss";

.p-card {
  position: relative;
  background: $white;
  border: 1px solid $darkgrey6;
  box-sizing: border-box;
  border-radius: 8px;
  box-shadow: none;
  font-family: "Rubik", sans-serif;
  overflow: hidden;
  .p-card-content {
    padding: 0;
  }
}

.remove-padding {
  .p-card-body {
    padding: 0;
  }
}
