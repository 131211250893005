@import "./colors-palette.scss";

.p-datatable-table {
  .p-datatable-thead {
    th {
      background: #fcfcfd !important;
      font-weight: 500;
      font-size: 14px;
      color: rgba($color: #667085, $alpha: 0.6) !important;
      border: 0;
      padding: 12px 1rem;

      &.label-dark {
        color: #30313d !important;
      }
    }
  }
  .p-datatable-tbody {
    tr {
      &.highlight-row {
        background-color: rgba($color: #e54f6d, $alpha: 0.05);
      }

      &.highlight-review-row {
        background-color: #f2f0ff;

        td {
          &.review-highlight-text {
            color: #8270db;
          }
          &:first-child {
            border-left: 3px solid #8270db;
          }
        }
      }

      &:last-child td {
        border-bottom: 0;
      }

      @media (max-width: 580px) {
        overflow: hidden;
        td {
          &:first-child {
            background-color: #f8f8f8;
          }
        }
      }
    }
    td {
      padding: 14px 1rem;
      font-size: 14px;
      color: #222222;
      letter-spacing: -0.02em;

      &.color-light {
        color: rgba($color: #222222, $alpha: 0.65);
      }
    }
  }

  th,
  td {
    border-color: #eeeeee;
  }
}

.p-datatable {
  .p-datatable-header {
    background: #ffffff;
    border: 0;
  }
}

.table-with-rounded-corners {
  .p-datatable {
    border: 1px solid $lightpurple2;
    box-sizing: border-box;
    border-radius: 8px;
    overflow: auto;
  }
}

.table-mobile-hide {
  display: none;
}

@media (max-width: 580px) {
  .p-datatable-table .p-datatable-thead th {
    display: none !important;
  }
  .p-datatable-table .p-datatable-tbody td {
    display: flex !important;
    width: 100% !important;
    align-items: center !important;
    justify-content: space-between !important;
  }
  .p-datatable .p-datatable-tbody > tr {
    margin: 20px 0;
    display: block;
    border: 1px solid #ccc;
    border-radius: 10px;
  }
  .p-datatable {
    border: none !important;
  }
  .table-mobile-hide {
    font-weight: 500;
    color: #000;
    display: block !important;
    width: 150px;
  }
  .hide-mobile-tr {
    display: none !important;
  }
}

.fixed-header-table {
  p-table {
    .p-datatable-thead {
      position: sticky;
      top: 0;
      z-index: 2;
    }
  }
}

.admin-academic-records-table {
  background-color: #000;
  .admin-academic-records-table-header {
    th {
      background-color: rgba($color: #ebeef1, $alpha: 0.85) !important;
    }
  }

  .admin-academic-records-subtable {
    .p-datatable-table {
      background-color: rgba($color: #ebeef1, $alpha: 0.45) !important;
    }
    .p-datatable-thead {
      th {
        background-color: rgba($color: #ebeef1, $alpha: 0.65) !important;
      }
    }
  }
}

.judges-table {
  .p-datatable-table {
    border-collapse: collapse;
    .p-datatable-thead {
      th {
        padding: 0.5rem;
        background-color: $white !important;

        &:not(:last-child) {
          border-right: 1px solid #e9eef2;
        }

        &:last-child {
          width: 280px;

          &.checkbox-cell {
            width: auto;
          }
        }
      }
    }
    .p-datatable-tbody {
      td {
        font-weight: 500;
        color: #182a4e;
        font-size: 14px;
        padding: 12px 0.5rem;
        border-top: 1px solid #e9eef2;
        border-bottom: 1px solid #e9eef2 !important;
        &:not(:last-child) {
          border-right: 1px solid #e9eef2;
        }
        .btn {
          font-size: 14px;
        }

        &:last-child {
          width: 280px;
          
          &.checkbox-cell {
            width: auto;
          }
        }
      }
    }
  }

  &.bordered-top-table {
    .p-datatable-table {
      .p-datatable-thead {
        th {
          border-top: 1px solid #e9eef2;
        }
      }
    }
  }

  &.striped-table {
    display: block;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    overflow: hidden;

    .p-datatable-thead {
      th {
        color: #44546f !important;
        font-weight: 400;
      }
    }

    .p-datatable-tbody {
      td {
        font-weight: 400;
        &.criteria-header {
          color: #44546f;
          background-color: #f5f6f7;
          font-weight: 500;
        }

        &:not(.criteria-header) {
          &:not(:last-child) {
            border-right: 1px solid #e0e0e0;
          }
        }

        &.checkbox-cell {
          text-align: center;
        }
      }
    }

    .p-datatable-tfoot {
      td {
        &.highlighted-row {
          font-size: 14px;
          padding: 0.5rem;
          background-color: #ecf4ff;
          color: $judge-primary;
          font-weight: 500;
          &:last-child {
            border-bottom-right-radius: 10px;
          }

          &:first-child {
            border-bottom-left-radius: 10px;
          }
        }
      }
    }
  }
}
