@function grid-columns($num) {
  @return repeat($num, minmax(0, 1fr));
}

@mixin generate-grid-cols($max-cols, $custom-breakpoints: null) {
  @for $i from 1 through $max-cols {
    // Default class without any breakpoint (base class)
    .grid-cols-#{$i} {
      grid-template-columns: grid-columns($i);
    }

    // Generate responsive classes if breakpoints are provided
    @if $custom-breakpoints {
      @each $breakpoint, $size in $custom-breakpoints {
        .grid-cols-#{$breakpoint}-#{$i} {
          @media (min-width: $size) {
            grid-template-columns: grid-columns($i);
          }
        }
      }
    }
  }
}

// Define breakpoints
$custom-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1600px,
);

// Generate grid classes for 1 to 6 columns with responsive breakpoints
@include generate-grid-cols(6, $custom-breakpoints);
